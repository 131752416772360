<template>
  <div class="goods-list">
    <MainHeader />
    <CommonTop />
    <div class="nav-box">
      <div class="page-content-wrap">
        <Nav />
      </div>
    </div>
    <div class="section">
      <div class="breadcrumb page-content-wrap">
        <span>所在位置：</span>
        <div class="breadcrumb-item">
          <router-link class="nav-item" to="/">首页</router-link>
        </div>
        <div v-if="navData.parent" class="breadcrumb-item">
          <span class="icon iconfont icon-hangdongjiantou"></span>
          <router-link class="nav-item" :to="'/category/list?id=' + navData.parent.id">{{ navData.parent &&
            navData.parent.name }}</router-link>
        </div>
        <div class="breadcrumb-item">
          <span class="icon iconfont icon-hangdongjiantou"></span>
          <span class="nav-item">{{ navData.name }}</span>
        </div>
      </div>
    </div>
    <div v-if="shapeList && shapeList.length > 0" class="section">
      <div class="page-content-wrap shape">
        <div class="head">
          <div class="label">形状筛选</div>
        </div>
        <div v-if="shapeList.length > 0" class="shapelist-container">
          <div class="shape-list">
            <div @click="shapefilter(item)" v-for="(item, index) in shapeList" :key="index"
              :class="[goodsParams.shape_option_id === item.id ? 'active' : '', 'shape-item']">
              <div @click.stop="nothingHandle" class="shape-cover">
                <!-- <img :src="item.pic" alt=""> -->
                <el-image style="max-width: 100%;" :src="item.pic" :preview-src-list="[item.pic]">
                </el-image>
              </div>
              <div class="shape-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(conditionList && conditionList.length > 0) || (paramsList && paramsList.length > 0)" class="section">
      <div class="page-content-wrap condition">
        <div class="head">
          <div class="label">条件筛选</div>
        </div>
        <div :class="[showAll2 ? 'more' : '', 'condition-list-container']">
          <div v-if="conditionList.length > 0 || paramsList.length > 0" class="condition-list">
            <template v-if="conditionList.length > 0">
              <div v-for="item in conditionList" :key="item.id" class="list-item">
                <div class="cat-name">{{ item.name }}</div>
                <div class="cat-list">
                  <div @click="conditionHandle(item, cat)" v-for="cat in item.child" :key="cat.id"
                    :class="[cat.selected ? 'active' : '', 'cat-item']">
                    <span v-if="cat.selected" class="icon iconfont icon-fuxuankuang"></span>
                    <span v-else class="icon iconfont icon-fuxuankuang1"></span>
                    <span>{{ cat.name }}</span>
                  </div>
                </div>
              </div>
            </template>
<!--            <template v-if="paramsList.length > 0">-->
<!--              <div v-for="item in paramsList" :key="item.id" class="list-item">-->
<!--                <div class="cat-name">{{ item.name }}</div>-->
<!--                <div class="cat-list">-->
<!--                  <div @click="paramsHandle(item, cat)" v-for="cat in item.child" :key="cat.id"-->
<!--                    :class="[cat.selected ? 'active' : '', 'cat-item']">-->
<!--                    <span v-if="cat.selected" class="icon iconfont icon-fuxuankuang"></span>-->
<!--                    <span v-else class="icon iconfont icon-fuxuankuang1"></span>-->
<!--                    <span>{{ cat.name }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
          </div>
        </div>
        <div class="show-all">
          <div @click="showAll2 = !showAll2">{{ showAll2 ? '收起' : '展开' }}</div>
        </div>
      </div>
    </div>
    <div v-if="goodsList && goodsList.length > 0" class="section">
      <div class="page-content-wrap goods-list-container">
        <div class="head">
          <div class="label">可选商品</div>
        </div>
        <div class="goods-list-header">
          <div class="list-header-item">图例</div>
          <div class="list-header-item">系列名称</div>
          <div class="list-header-item">产品属性</div>
        </div>
        <div class="goods-list">
          <div @click="jumpGoodsDetail(item)" v-for="item in goodsList" :key="item.id" class="goods-item">
            <div class="cover">
              <img :src="item.cover" alt="">
            </div>
            <div class="goods-name">
              {{ item.name }}
            </div>
            <div class="goods-options">
              <div class="options">
                <div v-for="opt in item.condition" :key="opt.id" class="opt-item">
                  <div class="opt-name">{{ opt.parent.name }}：{{ opt.name }}</div>
                </div>
                <div v-for="opt in item.params_skus_has_selections" :key="opt.pid" class="opt-item">
                  <div class="opt-name">{{ opt.name }}：</div>
                  <div class="opt-list">
                    <span v-for="(val, key) in opt.data" :key="key * val.id">{{ key === 0 ? '' : ' / ' }}{{ val.name
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="options">
                <div v-for="opt in item.params_skus_has_selections" :key="opt.pid" class="opt-item">
                    <div class="opt-name">{{opt.name}}：</div>
                    <div class="opt-list">
                      <span v-for="(val, key) in opt.data" :key="key * val.id">{{key === 0 ? '' : ' / '}}{{val.name}}</span>
                    </div>
                  </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="pager-box">
          <el-pagination background layout="prev, pager, next, total" @current-change="pageChange"
            :page-size="goodsParams.per_page" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-else class="section">
      <div class="page-content-wrap goods-list-container">
        <div class="head">
          <div class="label">可选商品</div>
        </div>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import Nav from '@/components/nav/index.vue'
export default {
  components: {
    MainHeader,
    CommonTop,
    Nav
  },
  data() {
    return {
      category_id: null,
      navData: {},
      shapeList: [],
      conditionList: [],
      paramsList: [],
      goodsParams: {
        page: 1,
        per_page: 10,
        category_id: '',
        shape_option_id: '',
        condition_option_ids: [],
        params_option_ids: []
      },
      goodsList: [],
      total: 0,
      showAll2: false
    }
  },
  created() {
    this.category_id = this.$route.query.id
    this.goodsParams.category_id = this.category_id
    this.getNavData()
    // this.getShapeData()
    // this.getConditionData()
    // this.getParamsConditionData()
    this.getGoodsList()
  },
  methods: {
    nothingHandle() {
      return false
    },
    // 跳转详情页
    jumpGoodsDetail(item) {
      this.$router.push({ path: '/goods/detail', query: { id: item.id } })
    },
    // 获取分页数据
    pageChange(page) {
      console.log(page)
      this.goodsParams.page = page
      this.getGoodsList()
    },
    // 获取商品列表
    async getGoodsList() {
      const _this = this
      const res = await this.$http.get('/api/goods', this.goodsParams)
      this.shapeList = res.shapeOption
      res.conditionOption.forEach(item => {
        item.child.forEach(val => {
          if (_this.goodsParams.condition_option_ids.some((con => { return con == val.id }))) {
            val.selected = true
          } else {
            val.selected = false
          }
        })
      })
      this.conditionList = res.conditionOption
      res.paramsSelection.forEach(item => {
        item.child.forEach(val => {
          if (_this.goodsParams.params_option_ids.some((con => { return con == val.id }))) {
            val.selected = true
          } else {
            val.selected = false
          }
        })
      })
      this.paramsList = res.paramsSelection
      res.goods.data.forEach(item => {
        let arr = []
        item.params_skus_has_selections.forEach(val => {
          if (arr.some(v => { return v.pid === val.pid })) {
            arr.forEach(m => {
              if (m.pid === val.pid) {
                if (!m.data.some(c => { return c.id === val.id })) {
                  m.data.push(val)
                }
              }
            })
          } else {
            arr.push({ pid: val.pid, name: val.parent?.name, data: [val] })
          }
        })
        console.log(arr, 123123)
        item.params_skus_has_selections = arr
      })
      console.log('商品列表：', res)
      this.total = res.goods.total
      this.goodsList = res.goods.data
      console.log('this.goodsList', this.goodsList)
    },
    // 获取参数筛选数据
    async getParamsConditionData() {
      const res = await this.$http.get(`common/category/params-option/${this.category_id}`)
      console.log('参数条件数据：', res)
      // 添加选中状态   默认全部未选中
      res.forEach(item => {
        item.child.forEach(val => {
          val.selected = false
        })
      })
      this.paramsList = res
    },
    // 获取条件数据
    async getConditionData() {
      const res = await this.$http.get(`common/category/condition-option/${this.category_id}`)
      console.log('条件数据：', res)
      // 添加选中状态   默认全部未选中
      res.forEach(item => {
        item.child.forEach(val => {
          val.selected = false
        })
      })
      this.conditionList = res
    },
    // 获取形状数据
    async getShapeData() {
      const res = await this.$http.get(`/common/category/shape-option/${this.category_id}`)
      // console.log('形状数据：', res)
      this.shapeList = res
    },
    // 获取面包屑导航数据
    async getNavData() {
      const res = await this.$http.get('/common/category/nav', { category_id: this.category_id })
      this.navData = res
    },
    // 参数筛选
    paramsHandle(parent, val) {
      parent.child.forEach(item => {
        if (item.id === val.id) {
          item.selected = !item.selected
          const idIndex = this.goodsParams.params_option_ids.indexOf(val.id)
          console.log(idIndex, val)
          if (idIndex === -1) {
            this.goodsParams.params_option_ids.push(val.id)

          } else {
            this.goodsParams.params_option_ids.splice(idIndex, 1)
          }
        } else {
          item.selected = false
          const idIndex = this.goodsParams.params_option_ids.indexOf(item.id)
          if (idIndex != -1) {
            this.goodsParams.params_option_ids.splice(idIndex, 1)
          }
        }
      })
      console.log(this.goodsParams, 99)
      this.goodsParams.page = 1
      this.getGoodsList()
    },
    // 条件筛选
    conditionHandle(parent, val) {
      parent.child.forEach(item => {
        if (item.id === val.id) {
          item.selected = !item.selected
          const idIndex = this.goodsParams.condition_option_ids.indexOf(val.id)
          console.log(idIndex, val)
          if (idIndex === -1) {
            this.goodsParams.condition_option_ids.push(val.id)

          } else {
            this.goodsParams.condition_option_ids.splice(idIndex, 1)
          }
        } else {
          item.selected = false
          const idIndex = this.goodsParams.condition_option_ids.indexOf(item.id)
          if (idIndex != -1) {
            this.goodsParams.condition_option_ids.splice(idIndex, 1)
          }
        }
      })
      console.log(this.goodsParams, 99)
      this.goodsParams.page = 1
      this.getGoodsList()
      // console.log(item, this.goodsParams.condition_option_ids.indexOf(item.id))
      // const idIndex = this.goodsParams.condition_option_ids.indexOf(item.id)

    },
    // 形状筛选
    shapefilter(item) {
      console.log(item)
      if (this.goodsParams.shape_option_id === item.id) {
        this.goodsParams.shape_option_id = ''
      } else {
        this.goodsParams.shape_option_id = item.id
      }
      console.log(this.goodsParams)
      this.goodsParams.page = 1
      this.getGoodsList()
    }
  }
}
</script>
<style lang="scss" scoped>
.goods-list {
  .section {
    //background-color: #f5f6fb;
    background-color: #f5f5f5;

    .head {
      .label {
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
        //border-left: 3px solid #3385FF;
        border-left: 3px solid #D9262C;
      }
    }
  }

  .breadcrumb {
    padding-top: 20px;
    display: flex;
    font-size: 14px;
    color: #999999FF;

    .breadcrumb-item {
      margin-right: 10px;

      a {
        color: #999999;
        text-decoration: none;
      }

      .iconfont {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }

  .shape {
    padding-top: 30px;

    .shapelist-container {
      background-color: #ffffff;
      border-radius: 3px;
      margin-top: 20px;
      padding: 15px;
      max-height: 360px;
      overflow-y: scroll;

      /*滚动条整体粗细样式*/
      &::-webkit-scrollbar {
        /*高宽分别对应横竖滚动条的尺寸*/
        width: 3px;
      }

      /*滚动条里面小方块*/
      &::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
        background: #D9262C !important;
        /* 颜色 */
        /* background:#b6b6b6!important; */
        /* 线性渐变背景 */
        // background-image: linear-gradient(45deg, #ffbd61 25%,#ffbd61 25%, #ff8800 25%, #ff8800 50%,#ffbd61 50%, #ffbd61 75%, #ff8800 75%, #ff8800 100%)!important;
      }

      /*滚动条轨道*/
      &::-webkit-scrollbar-track {
        border-radius: 10px !important;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
        background: #EDEDED !important;
      }

      .shape-list {
        display: flex;
        flex-wrap: wrap;

        .shape-item {
          display: flex;
          align-items: center;
          width: 281px;
          height: 100px;
          background-color: #ffffff;
          padding: 15px;
          margin-right: 14px;
          margin-top: 15px;
          border-radius: 5px;
          border: 1px solid #F8F8F9;
          overflow: hidden;
          cursor: pointer;

          &.active {
            border: 1px solid #D9262C;
            background-image: url(../../assets/images/common/1.png);
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 30px;
          }

          &:nth-child(-n + 4) {
            margin-top: 0;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }

          .shape-cover,
          .shape-name {
            flex: 1;
          }

          .shape-name {
            text-align: center;
            padding-left: 5px;
            box-sizing: border-box;
            line-height: 1.5;
            // text-overflow: -o-ellipsis-lastline;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            // line-clamp: 1;
            // -webkit-box-orient: vertical;
          }

          .shape-cover {
            display: flex;
            justify-content: center;
            align-items: center;

            //background-color: #ffffff;
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .show-all {
    display: flex;
    justify-content: center;

    div {
      width: 150px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #D9262C;
      color: #ffffff;
      cursor: pointer;
      border-radius: 0 0 5px 5px;

      &:hover {
        opacity: .8;
      }
    }
  }

  .condition {
    padding-top: 30px;

    .condition-list-container {
      height: 180px;
      overflow: hidden;

      &.more {
        height: auto;
      }
    }

    .condition-list {
      margin-top: 20px;
      border-radius: 3px;
      overflow: hidden;

      .list-item {
        display: flex;

        &:last-child {
          .cat-name {
            border-bottom: 1px solid #eeeff3;
          }

          .cat-list {
            border-bottom: 0 none;
          }
        }

        .cat-name {
          background-color: #ececec;
          width: 150px;
          border-bottom: 1px solid #ffffff;
          line-height: 39px;
          padding: 0 15px;
        }

        .cat-list {
          display: flex;
          flex-wrap: wrap;
          background-color: #ffffff;
          flex: 1;
          border-bottom: 1px solid #E8E8E8;
          padding-left: 15px;

          .cat-item {
            line-height: 39px;
            margin-right: 25px;
            cursor: pointer;
            min-width: 110px;

            &.active {
              color: #D9262C;
            }

            .iconfont {
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

  .goods-list-container {
    .goods-list-header {
      margin-top: 20px;
      padding: 0 15px;
      display: flex;
      background: #ececec;
      height: 40px;

      .list-header-item {
        line-height: 40px;
        font-weight: bold;

        &:nth-child(1) {
          width: 100px;
        }

        &:nth-child(2) {
          width: 25%;
          padding: 0 10px;
          margin-left: 20px;
        }

        &:nth-child(3) {
          flex: 1;
          padding: 0 10px;
        }
      }
    }

    padding: 30px 0;

    .goods-list {

      // margin-top: 20px;
      // display: flex;
      // flex-wrap: wrap;
      // align-items: flex-start;
      // min-height: 300px;
      .goods-item {
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #e7e7e7;
        cursor: pointer;

        &:hover {
          background-color: #f3e9e9;

          .goods-name {
            color: #d9262c;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #f1d6d6;
          }
        }

        .cover {
          width: 100px;
          height: 100px;
          border: 1px solid #e7e7e7;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .goods-name {
          padding: 0 10px;
          width: 25%;
          font-weight: bold;
          margin-left: 20px;
        }

        .goods-options {
          font-size: 13px;
          color: #4a4a4a;
          flex: 1;
          display: flex;
          // align-items: center;
          padding: 0 10px;
          max-height: 110px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 6px;
            height: 4px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px #f1d6d6;
            background-color: #f1d6d6;
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px #f1d6d6;
            border-radius: 5px;
            background-color: #f5f6f8;
          }

          .options {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            // &:last-child {
            //   margin-left: 20px;
            // }
            .opt-item {
              margin-bottom: 10px;
              line-height: 1.5;
              display: flex;
              width: 50%;

              &:nth-child(2n) {
                padding-left: 20px;
              }

              .opt-list {
                flex: 1;
              }
            }
          }
        }

        // width: 232px;
        // background-color: #ffffff;
        // border-radius: 3px;
        // margin-right: 10px;
        // margin-top: 10px;
        // padding: 20px 20px 30px;
        // cursor: pointer;
        // transition: all .2s;
        // // &:hover {
        // //   box-shadow: 0 15px 30px rgba(0,0,0,.1);
        // //   transform: translate3d(0,-2px,0);
        // // }
        // &:nth-child(5n) {
        //   margin-right: 0;
        // }
        // &:nth-child(-n + 5) {
        //   margin-top: 0;
        // }
        // .goods-pic {
        //   width: 180px;
        //   height: 180px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   img {
        //     max-width: 100%;
        //     max-height: 100%;
        //   }
        // }
        // .goods-name {
        //   margin-top: 30px;
        //   text-align: center;
        //   font-size: 14px;
        //   color: #3385FF;
        //   text-overflow: -o-ellipsis-lastline;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 1;
        //   line-clamp: 1;
        //   -webkit-box-orient: vertical;
        // }
      }
    }

    .pager-box {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      ::v-deep .el-pagination.is-background .el-pager li {
        background-color: #f5f5f5;
        min-width: 27px;
        height: 27px;
        line-height: 27px;

        &:not(.disabled).active {
          background: #D9262C;
          color: #F5F5F5;
        }

        &:hover {
          color: #D9262C;
        }
      }
    }
  }
}

::v-deep {
  .goods-list .goods-list-container .pager-box[data-v-96e9ac82] .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #D9262C;
  }
}
</style>
